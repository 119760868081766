// PageContent.scss
@use '../global';

.page-content {
    margin-left: 25%;
    background-color: transparentize(global.$background-color, 0.15);

    // Wrapper
    &--inner {
        max-width: 1400px;
        padding: 48px 40px;
    }

    &__title {
        margin: 0;
        padding-bottom: 24px;
        color: #00E99F;
        text-transform: uppercase;
    }
}

@media (max-width: 1096px) {
    .page-content {
        margin-left: 0;

        &--inner {
            padding: 24px 20px;
        }
    }
}