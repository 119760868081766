// _page.scss
@use 'global';

$background-image-url: url('./assets/backgrounds/abstract-simple-colors-8k-jk.jpg');

body {
    margin: 0;
    padding: 0;
    font-family: global.$font-stack;
    color: global.$foreground-color;
    background-image: $background-image-url;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
}

h1 {
    font-size: 1.4em;
}

h2 {
    font-size: 1.2em;
}

h3 {
    font-size: 1em;
}

p.has-header {
    margin-top: 0;
}

a {
    color: inherit;
    text-decoration: none;
}

@media (max-width: 684px) {
    h1 {
        font-size: 1.2em;
    }

    h2 {
        font-size: 1em;
    }

    h3, p, a, li {
        font-size: 0.8em;
    }
}