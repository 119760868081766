// _global.scss
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap');

$font-system-ui: -apple-system, BlinkMacSystemFont;
$font-known-system-ui: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans';
$font-fallback: 'Helvetica Neue', sans-serif;

$font-stack: $font-system-ui, $font-known-system-ui, $font-fallback;

$primary-color:    #00E99F;
$foreground-color: #FAFAFA;
$background-color: #1C1E1F;

$grey:             #929292;
$sky-blue:         #00D4FF;
$ocean-blue:       #0055FF;
$purply-blue:      #2A00FF;
$purple:           #BB32FF;
$pink:             #FF00D4;
$vibrant-pink:     #FF0054;
$red:              #FF001D;
$orange:           #FF2A00;
$light-orange:     #FFAA00;
$yellow:           #FFFF00;
$green:            #2CC66C;

