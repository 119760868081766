// Resume.scss

.resume__document {
    min-height: calc(100vh - 150px);
    margin-top: 16px;
    filter: brightness(95%);
}

.resume__page {
    display: flex;
    flex-direction: column;

    & > canvas {
        margin-left: 64px;
        //align-self: center;
    }

    &:nth-of-type(1) > canvas {
        border-radius: 16px;
    }

    &:nth-of-type(2) > canvas {
        border-radius: 0 0 16px 16px;
    }
}

@media (max-width: 1096px) {
    .resume__page {
        & > canvas {
            margin-left: unset;
        }
    }
}